<template>
  <div style="padding: 0 40px 40px 40px">
    <div v-if="isLoadind">
      <span
        class="spinner-grow spinner-grow"
        style="width: 1rem; height: 1rem"
        role="status"
        aria-hidden="true"
      ></span>
      <i style="font-size: 16px">正在載入...</i>
    </div>
    <Datatable
      :table-data="tableData"
      :table-header="tableHeader"
      :enable-items-per-page-dropdown="true"
      :openTextEnd="false"
      :striped="true"
    >
      <template v-slot:cell-title="{ row: tableData }">
        {{ tableData.title }}
      </template>
      <template v-slot:cell-status="{ row: tableData }">
        {{ tableData.status }}
      </template>
      <template v-slot:cell-isTop="{ row: tableData }">
        {{ tableData.isTop }}
      </template>
      <template v-slot:cell-beginTime="{ row: tableData }">
        {{ tableData.beginTime }}
      </template>
      <template v-slot:cell-endTime="{ row: tableData }">
        {{ tableData.endTime }}
      </template>
      <template v-slot:cell-createTime="{ row: tableData }">
        {{ tableData.createTime }}
      </template>
      <template v-slot:cell-operate="{ row: tableData }">
        <el-dropdown>
          <span
            @mouseover="setCurrentAnnouncement(tableData)"
            class="el-dropdown-link text-primary"
          >
            功能選單<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                data-bs-toggle="modal"
                data-bs-target="#announcementTransactionRecord-modal"
                >異動紀錄</el-dropdown-item
              >
              <el-dropdown-item @click="toViewAnnouncement()"
                >檢視內容</el-dropdown-item
              >
              <el-dropdown-item
                data-bs-toggle="modal"
                data-bs-target="#sendNotification-modal"
                >發送通知</el-dropdown-item
              >
              <el-dropdown-item
                v-if="tableData.isTop == '否'"
                @click="setTopAnnouncement()"
                >置頂</el-dropdown-item
              >
              <el-dropdown-item
                v-if="tableData.isTop == '是'"
                @click="setUnTopAnnouncement()"
                >解除置頂</el-dropdown-item
              >
              <el-dropdown-item @click="handleTerminateAnnouncement()"
                >終止</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </Datatable>
  </div>
  <!-- 公告異動紀錄modal -->
  <div
    class="modal fade"
    id="announcementTransactionRecord-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="announcementTransactionRecord-exampleModalLabel"
          >
            公告異動紀錄
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <TransactionRecord
            :currentAnnouncement="currentAnnouncement"
          ></TransactionRecord>
        </div>
      </div>
    </div>
  </div>
  <!-- 發送通知modal -->
  <div
    class="modal fade"
    id="sendNotification-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sendNotification-exampleModalLabel">
            發送通知
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SendNotification
            @onSendNotify="onSendNotify()"
            :currentAnnouncement="currentAnnouncement"
          ></SendNotification>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getPublishAnnouncements,
  topAnnouncement,
  untopAnnouncement,
  terminateAnnouncement,
} from "@/core/services/api/announcement";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import TransactionRecord from "./TransactionRecord.vue";
import SendNotification from "./SendNotification.vue";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    TransactionRecord,
    SendNotification,
  },
  emits: ["onSendNotify"],
  setup() {
    let sendNotificationModal: any;
    const router = useRouter();
    const isLoadind = ref(false);
    const currentAnnouncement: any = reactive({});
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        key: "title",
        name: "標題",
        sortable: true,
      },
      {
        key: "status",
        name: "狀態",
        sortable: true,
      },
      {
        key: "isTop",
        name: "置頂",
        sortable: true,
      },
      {
        key: "beginTime",
        name: "起始時間",
        sortable: true,
      },
      {
        key: "endTime",
        name: "結束時間",
        sortable: true,
      },
      {
        key: "createTime",
        name: "建立時間",
        sortable: true,
      },
      {
        key: "operate",
        name: "功能",
        sortable: false,
      },
    ]);
    const statusMap = {
      Draft: "草稿",
      Pending: "等待發佈",
      Publishing: "發佈中",
      Expired: "已過期",
      Termination: "已終止",
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let publishAnnouncements = await getPublishAnnouncements(
        currentSpaceGuid!
      );
      tableData.splice(0);
      publishAnnouncements.forEach((o) => {
        tableData.push({
          title: o.title,
          status: statusMap[o.status],
          isTop: o.isTop ? "是" : "否",
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          createTime: getLocalTimeString(o.createTime),
          guid: o.guid,
        });
      });
      isLoadind.value = false;
    };

    const setCurrentAnnouncement = (item) => {
      Object.assign(currentAnnouncement, item);
    };

    const toViewAnnouncement = () => {
      router.push({
        path: `/routineService/announcementManagement/viewAnnouncement/${currentAnnouncement.guid}`,
      });
    };

    const onSendNotify = () => {
      sendNotificationModal.hide();
    };

    onMounted(() => {
      sendNotificationModal = new Modal(
        document.getElementById("sendNotification-modal")
      );
    });

    const setTopAnnouncement = async () => {
      await topAnnouncement(currentSpaceGuid!, currentAnnouncement.guid);
      await Swal.fire("置頂成功!", "", "success");
      await setTableData();
    };
    const setUnTopAnnouncement = async () => {
      await untopAnnouncement(currentSpaceGuid!, currentAnnouncement.guid);
      await Swal.fire("取消置頂成功!", "", "success");
      await setTableData();
    };

    const handleTerminateAnnouncement = async () => {
      await terminateAnnouncement(currentSpaceGuid!, currentAnnouncement.guid);
      await Swal.fire("公告已終止!", "", "success");
      await setTableData();
    };

    const init = () => {
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      isLoadind,
      setCurrentAnnouncement,
      currentAnnouncement,
      toViewAnnouncement,
      onSendNotify,
      setTopAnnouncement,
      setUnTopAnnouncement,
      handleTerminateAnnouncement,
    };
  },
});
</script>

<style>
</style>