
import { defineComponent, reactive, ref, watch } from "vue";
import { getAnnouncementLog } from "@/core/services/api/announcementLog";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";

export default defineComponent({
  components: {
    Datatable,
  },
  props: ["currentAnnouncement"],
  setup(props) {
      const actionMap = {
        CreateDraft: "建立草稿",
        ModifyDraft: "修改草稿",
        Publish: "發佈",
        Termination: "中止",
        NotifyUser: "通知使用者",
        Top: "置頂",
        UnTop: "取消置頂",
        Resume: "恢復發佈",
      };
      const tableData: any = reactive([]);
      const tableHeader = ref([
      {
        name: "操作人員",
        key: "operator",
        sortable: true,
      },
      {
        name: "異動項目",
        key: "item",
        sortable: true,
      },
      {
        name: "相關參數",
        key: "parameter",
        sortable: true,
      },
      {
        name: "異動時間",
        key: "createTime",
        sortable: true,
      }
    ]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    const setTableData = async () => {
      let announcementLog:any = await getAnnouncementLog(
        currentSpaceGuid!,
        props.currentAnnouncement.guid
      );
      tableData.splice(0)
      announcementLog.forEach(o => {
          tableData.push({
              operator:o.handler.name,
              item:actionMap[o.action],
              parameter:o.parameter,
              createTime:getLocalTimeString(o.createTime)
          })
      });
    };

    watch(
      props,
      async () => {
        await setTableData();
      },
      {
        deep: true,
        immediate:true
      }
    );

    return {
        tableData,
        tableHeader
    }
  },
});
