import ApiService from "@/core/services/ApiService";
import { AnnouncementLog } from "@/core/services/responsesModel/announcementLog"

// 取得公告異動紀錄
export function getAnnouncementLog(spaceGuid: string,announcementGuid:string): Promise<AnnouncementLog> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Announcement/${announcementGuid}/AnnouncementLog`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}