<template>
  <div style="padding: 0 40px 40px 40px">
    <div v-if="isLoadind">
      <span
        class="spinner-grow spinner-grow"
        style="width: 1rem; height: 1rem"
        role="status"
        aria-hidden="true"
      ></span>
      <i style="font-size: 16px">正在載入...</i>
    </div>
    <Datatable
      :table-data="tableData"
      :table-header="tableHeader"
      :enable-items-per-page-dropdown="true"
      :openTextEnd="false"
      :striped="true"
    >
      <template v-slot:cell-title="{ row: tableData }">
        {{ tableData.title }}
      </template>
      <template v-slot:cell-status="{ row: tableData }">
        {{ tableData.status }}
      </template>
      <template v-slot:cell-beginTime="{ row: tableData }">
        {{ tableData.beginTime }}
      </template>
      <template v-slot:cell-endTime="{ row: tableData }">
        {{ tableData.endTime }}
      </template>
      <template v-slot:cell-createTime="{ row: tableData }">
        {{ tableData.createTime }}
      </template>
      <template v-slot:cell-operate="{ row: tableData }">
        <el-dropdown>
          <span
            @mouseover="setCurrentAnnouncement(tableData)"
            class="el-dropdown-link text-primary"
          >
            功能選單<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="toEditAnnouncement()"
                >編輯</el-dropdown-item
              >
              <el-dropdown-item @click="handlePublishAnnouncement()"
                >發佈</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </Datatable>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import {
  getDraftAnnouncements,
  publishAnnouncement,
} from "@/core/services/api/announcement";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const isLoadind = ref(false);
    const currentAnnouncement: any = reactive({});
    const router = useRouter();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        key: "title",
        name: "標題",
        sortable: true,
      },
      {
        key: "status",
        name: "狀態",
        sortable: true,
      },
      {
        key: "beginTime",
        name: "起始時間",
        sortable: true,
      },
      {
        key: "endTime",
        name: "結束時間",
        sortable: true,
      },
      {
        key: "createTime",
        name: "建立時間",
        sortable: true,
      },
      {
        key: "operate",
        name: "功能",
        sortable: false,
      },
    ]);
    const statusMap = {
      Draft: "草稿",
      Pending: "等待發佈",
      Publishing: "發佈中",
      Expired: "已過期",
      Termination: "已終止",
    };

    const toEditAnnouncement = () => {
      router.push({
        path: `/routineService/announcementManagement/editAnnouncement/${currentAnnouncement.guid}`,
      });
    };

    const setCurrentAnnouncement = (item) => {
      Object.assign(currentAnnouncement, item);
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let draftAnnouncements = await getDraftAnnouncements(currentSpaceGuid!);
      tableData.splice(0);
      draftAnnouncements.forEach((o) => {
        tableData.push({
          guid: o.guid,
          title: o.title,
          status: statusMap[o.status],
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          createTime: getLocalTimeString(o.createTime),
        });
      });
      isLoadind.value = false;
    };

    //發佈公告
    const handlePublishAnnouncement = async () => {
      await Swal.fire({
        title: `您確定要發佈此公告嗎?`,
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await publishAnnouncement(
            currentSpaceGuid!,
            currentAnnouncement.guid
          );
          await Swal.fire("發佈成功!", "", "success");
          window.location.reload();
        }
      });
    };

    const init = () => {
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      toEditAnnouncement,
      setCurrentAnnouncement,
      isLoadind,
      handlePublishAnnouncement,
    };
  },
});
</script>
