
import { defineComponent, reactive, ref } from "vue";
import { sendNotify } from "@/core/services/api/announcement";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  props: ["currentAnnouncement"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      datePicker: [
        {
          required: true,
          message: "請選擇發送時間",
          trigger: "blur",
        },
      ],
    });
    const form: any = reactive({
      datePicker: "",
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要設定發送通知嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                sendTime: getUtcTimeString(form.datePicker),
              };
                await sendNotify(
                  currentSpaceGuid!,
                  props.currentAnnouncement.guid,
                  request
                );
              await Swal.fire("設定發送通知成功!", "", "success");
              emit("onSendNotify");
            }
          });
        }
      });
    };

    return {
      rules,
      formRef,
      form,
      submit,
    };
  },
});
