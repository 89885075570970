
import { defineComponent } from "vue";
import ReleaseArea from "./components/ReleaseArea.vue";
import DraftArea from "./components/DraftArea.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    ReleaseArea,
    DraftArea,
  },
  setup() {
    const router = useRouter();

    const toAddAnnouncement = () => {
      router.push({ name: "addAnnouncement" });
    };

    return {
      toAddAnnouncement,
    };
  },
});
