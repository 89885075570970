<template>
  <div class="card">
    <div
      class="d-flex align-items-center justify-content-between"
      style="padding: 40px 40px 0 40px"
    >
      <ul class="nav me-3">
        <li class="nav-item">
          <button
            class="
              nav-link
              btn btn-color-muted btn-active btn-active-light-primary
              active
              fw-bolder
              px-4
              me-1
            "
            data-bs-toggle="tab"
            data-bs-target="#pills-1"
          >
            發佈區
          </button>
        </li>

        <li class="nav-item">
          <button
            class="
              nav-link
              btn btn-color-muted btn-active btn-active-light-primary
              fw-bolder
              px-4
              me-1
            "
            data-bs-toggle="tab"
            data-bs-target="#pills-2"
          >
            草稿區
          </button>
        </li>
      </ul>
      <button
        type="button"
        class="btn btn-success"
        @click="toAddAnnouncement()"
      >
        新增公告
      </button>
    </div>
    <div class="row d-flex align-items-end">
      <div class="col-12 tab-content p-4" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <ReleaseArea></ReleaseArea>
        </div>
        <div
          class="tab-pane fade"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <DraftArea></DraftArea>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import ReleaseArea from "./components/ReleaseArea.vue";
import DraftArea from "./components/DraftArea.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    ReleaseArea,
    DraftArea,
  },
  setup() {
    const router = useRouter();

    const toAddAnnouncement = () => {
      router.push({ name: "addAnnouncement" });
    };

    return {
      toAddAnnouncement,
    };
  },
});
</script>

<style>
</style>