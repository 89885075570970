
import { defineComponent, reactive, ref } from "vue";
import {
  getDraftAnnouncements,
  publishAnnouncement,
} from "@/core/services/api/announcement";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const isLoadind = ref(false);
    const currentAnnouncement: any = reactive({});
    const router = useRouter();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        key: "title",
        name: "標題",
        sortable: true,
      },
      {
        key: "status",
        name: "狀態",
        sortable: true,
      },
      {
        key: "beginTime",
        name: "起始時間",
        sortable: true,
      },
      {
        key: "endTime",
        name: "結束時間",
        sortable: true,
      },
      {
        key: "createTime",
        name: "建立時間",
        sortable: true,
      },
      {
        key: "operate",
        name: "功能",
        sortable: false,
      },
    ]);
    const statusMap = {
      Draft: "草稿",
      Pending: "等待發佈",
      Publishing: "發佈中",
      Expired: "已過期",
      Termination: "已終止",
    };

    const toEditAnnouncement = () => {
      router.push({
        path: `/routineService/announcementManagement/editAnnouncement/${currentAnnouncement.guid}`,
      });
    };

    const setCurrentAnnouncement = (item) => {
      Object.assign(currentAnnouncement, item);
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let draftAnnouncements = await getDraftAnnouncements(currentSpaceGuid!);
      tableData.splice(0);
      draftAnnouncements.forEach((o) => {
        tableData.push({
          guid: o.guid,
          title: o.title,
          status: statusMap[o.status],
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          createTime: getLocalTimeString(o.createTime),
        });
      });
      isLoadind.value = false;
    };

    //發佈公告
    const handlePublishAnnouncement = async () => {
      await Swal.fire({
        title: `您確定要發佈此公告嗎?`,
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await publishAnnouncement(
            currentSpaceGuid!,
            currentAnnouncement.guid
          );
          await Swal.fire("發佈成功!", "", "success");
          window.location.reload();
        }
      });
    };

    const init = () => {
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      toEditAnnouncement,
      setCurrentAnnouncement,
      isLoadind,
      handlePublishAnnouncement,
    };
  },
});
