
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getPublishAnnouncements,
  topAnnouncement,
  untopAnnouncement,
  terminateAnnouncement,
} from "@/core/services/api/announcement";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import TransactionRecord from "./TransactionRecord.vue";
import SendNotification from "./SendNotification.vue";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    TransactionRecord,
    SendNotification,
  },
  emits: ["onSendNotify"],
  setup() {
    let sendNotificationModal: any;
    const router = useRouter();
    const isLoadind = ref(false);
    const currentAnnouncement: any = reactive({});
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        key: "title",
        name: "標題",
        sortable: true,
      },
      {
        key: "status",
        name: "狀態",
        sortable: true,
      },
      {
        key: "isTop",
        name: "置頂",
        sortable: true,
      },
      {
        key: "beginTime",
        name: "起始時間",
        sortable: true,
      },
      {
        key: "endTime",
        name: "結束時間",
        sortable: true,
      },
      {
        key: "createTime",
        name: "建立時間",
        sortable: true,
      },
      {
        key: "operate",
        name: "功能",
        sortable: false,
      },
    ]);
    const statusMap = {
      Draft: "草稿",
      Pending: "等待發佈",
      Publishing: "發佈中",
      Expired: "已過期",
      Termination: "已終止",
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let publishAnnouncements = await getPublishAnnouncements(
        currentSpaceGuid!
      );
      tableData.splice(0);
      publishAnnouncements.forEach((o) => {
        tableData.push({
          title: o.title,
          status: statusMap[o.status],
          isTop: o.isTop ? "是" : "否",
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          createTime: getLocalTimeString(o.createTime),
          guid: o.guid,
        });
      });
      isLoadind.value = false;
    };

    const setCurrentAnnouncement = (item) => {
      Object.assign(currentAnnouncement, item);
    };

    const toViewAnnouncement = () => {
      router.push({
        path: `/routineService/announcementManagement/viewAnnouncement/${currentAnnouncement.guid}`,
      });
    };

    const onSendNotify = () => {
      sendNotificationModal.hide();
    };

    onMounted(() => {
      sendNotificationModal = new Modal(
        document.getElementById("sendNotification-modal")
      );
    });

    const setTopAnnouncement = async () => {
      await topAnnouncement(currentSpaceGuid!, currentAnnouncement.guid);
      await Swal.fire("置頂成功!", "", "success");
      await setTableData();
    };
    const setUnTopAnnouncement = async () => {
      await untopAnnouncement(currentSpaceGuid!, currentAnnouncement.guid);
      await Swal.fire("取消置頂成功!", "", "success");
      await setTableData();
    };

    const handleTerminateAnnouncement = async () => {
      await terminateAnnouncement(currentSpaceGuid!, currentAnnouncement.guid);
      await Swal.fire("公告已終止!", "", "success");
      await setTableData();
    };

    const init = () => {
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      isLoadind,
      setCurrentAnnouncement,
      currentAnnouncement,
      toViewAnnouncement,
      onSendNotify,
      setTopAnnouncement,
      setUnTopAnnouncement,
      handleTerminateAnnouncement,
    };
  },
});
